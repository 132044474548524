<template>
  <footer>
    <section>
      <article class="newsLetterArticle">
        <div class="container">
          <div class="news_letter_form">
            <div class="text-center">
              <h2>Subscribe For Our NewsLetters</h2>
              <p>Get Notifications through your email about all the events and experiences at Adere Safari Lodges.</p>
              <el-form
                :model="newsLetterForm"
                :rules="rules"
                ref="newsLetterForm"
                label-width="120px"
                class="demo-newsLetterForm"
                label-position="top"
              >
                <el-form-item prop="email">
                  <el-input
                    v-model="newsLetterForm.email"
                    placeholder="Email"
                    class="input-with-select"
                  >
                    <el-button
                      slot="append"
                      style="background-color: #513F03; color: white; border-radius: 0px 5px 5px 0px;"
                      @click="submitForm('newsLetterForm')"
                    >Subscribe</el-button>
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="footer_links display-desktop">
        <div class="container">
          <div class="footer_links_grid">
            <div class="about_adere_in_footer">
              <h2>Adere Safari Lodges</h2>
              <p>
                A gorgeous safari lodge perched on the edge of Kidepo Valley National Park
                in a unique natural setting sitting on jagged and semi-arid rocks of Kidepo,
                that date back to over a millennia.
              </p>
              <ul>
                <li><i class="fab fa-facebook-f"></i></li>
                <li><i class="fab fa-twitter"></i></li>
                <li><i class="fab fa-instagram"></i></li>
                <li><i class="fab fa-linkedin"></i></li>
              </ul>
            </div>
            <div>
              <h2>Links</h2>
              <ul>
                <li @click="$router.push({ path: '/about-us'})">About Us</li>
                <li @click="$router.push({ path: '/accommodation'})">Accommodation</li>
                <li @click="$router.push({ path: '/experiences'})">Experiences</li>
                <li @click="$router.push({ path: '/gallery' })">Gallery</li>
                <li @click="$router.push({ path: '/dining' })">Dining</li>
              </ul>
            </div>
            <div>
              <h2>Support</h2>
              <ul>
                <li>FAQs.</li>
                <li>Features</li>
                <li @click="$router.push({ path: '/contact-us' })">Contact Us</li>
                <li>Reporting</li>
              </ul>
            </div>
            <div>
              <h2>Contact Us</h2>
              <ul>
                <!-- <li>+256 759 119780</li> -->
                <li>+256 706 269726</li>
                <li>info@aderesafarilodges.com</li>
                <li>Adere Safari Lodges</li>
              </ul>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="footer_links display-mobile">
        <div class="container">

        </div>
      </article>
    </section>

    <section>
      <article class="footer_bottom">
        <div class="container">
          <hr />
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <small>
                Design by <a
                  href="https://www.ovalspace.co"
                  target="_blank"
                >Ovalspace</a>
              </small>
            </div>
            <div>
              <small>Terms of Use</small> | <small>Privacy policy</small>
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
      <a
        href="https://wa.me/+256706269726/?text=Hello, Adere Safari Lodges"
        class="float"
        target="_blank"
      >
        <!--<i class="fa fa-whatsapp my-float"></i>-->
        <i class="fab fa-whatsapp my-float"></i>
      </a>
    </section>

  </footer>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeNames: ["1"],
      newsLetterForm: {
        email: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: "Please input Email",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    handleChange(val) {
      console.log(val);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
footer {
  background-color: #f9f7f3;
  padding-top: 50px;
  padding-bottom: 50px;
}

.newsLetterArticle {
  padding-top: 50px;
  padding-bottom: 100px;
}
.news_letter_form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news_letter_form > div {
  width: 42%;
}

.newsLetterArticle h2 {
  font-size: 2em;
  font-weight: 600;
}

.footer_links_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.footer_links_grid > div {
  /* border: 1px solid black; */
}

.footer_links_grid .about_adere_in_footer ul {
  display: flex;
}

.footer_links_grid .about_adere_in_footer ul li {
  border: 1px solid greenyellow;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_links_grid > div h2 {
  font-size: 1em;
  font-weight: 600;
  color: black;
}

.footer_links_grid > div p {
  font-size: 0.8em;
}

.footer_links_grid > div ul {
  padding: 0px;
}

.footer_links_grid > div ul li {
  /* border: 1px solid black; */
  list-style: none;
  font-size: 0.8em;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.my-float {
  margin-top: 16px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .news_letter_form > div {
    width: 80%;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .news_letter_form > div {
    width: 80%;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .news_letter_form > div {
    width: 100%;
  }
  .footer_links_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .news_letter_form > div {
    width: 100%;
  }
  .footer_links_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}
</style>

<style>
footer .el-collapse-item__header {
  background-color: #3d290a;
  border-bottom: 1px solid #ebeef5;
  color: #fef9f6;
  text-align: left;
}
footer .el-collapse-item {
  background-color: #3d290a;
  color: #eeeeee;
  text-align: left;
}
footer .el-collapse-item__content {
  border: none;
  background-color: #3d290a;
  color: #eeeeee;
  text-align: left;
}
footer .el-collapse-item__wrap {
  will-change: height;
  background-color: #3d290a;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 1px solid #ebeef5;
}
footer .el-input {
  background-color: #513f03;
  color: white;
  border-radius: 5px;
}
</style>